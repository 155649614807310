import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Layout, Tooltip, Modal, Form } from "antd";
import "antd/dist/antd.css";
import { Table, Input, Button, Space, Card, Switch, Spin, Upload, message } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as AdminActtions from "../../redux/actions/admin-actions";
import { HistoryOutlined } from "@ant-design/icons";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ReadOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import * as AuthActions from "../../redux/actions/auth-actions";
import { notifyUser } from "../../services/notification-service"

const dateFormathh = "MM/DD/YYYY HH:mm a ";

class ApprovedPatientList extends Component {
  formRef = React.createRef();
  state = {
    searchText: "",
    searchedColumn: "",
    loading: true,
    data: [],
    roleName: "",
    patienteData: [],
    patienteInactiveData: [],
    visible: false,
    visibleBulkImport: false,
    visibleHandleStatus: false,
    selectedFile: null,
    fileList: [],
    paradePatId : "",
    paradePatVal: "",
    isDsable: false,
  };

  // componentDidMount = () => {
  //   let studyId = localStorage.getItem("studyId");
  //   this.props
  //     .getApprovedPatientsListJanssen()
  //     .then((resp) => {
  //       this.setState({
  //         loading: false,
  //         patienteData: resp.data,
  //       });
  //     })
  //     .catch((ex) => {
  //       this.setState({ loading: false });
  //     });
  //   // if (this.props.approvedPatients) {
  //   //   this.setState({
  //   //     data: this.props.approvedPatients,
  //   //     loading: false
  //   //   });
  //   // }
  //   if (studyId == "1") {
  //     this.props
  //     .getApprovedPatientsList(studyId)
  //     .then((resp) => {
  //       this.setState({
  //         loading: false,
  //         data: resp.data,
  //       });
  //     })
  //     .catch((ex) => {
  //       this.setState({ loading: false });
  //     });
  //   }
  //   this.props
  //     .getLoggedInUser()
  //     .then((resp) => {
  //       this.setState({
  //         loading: false,
  //         firstName: resp.data.firstName,
  //         email: resp.data.email,
  //         roleName:resp.data.roles[0].name,
  //         avatar: resp.data.firstName.toString().substring(0, 1) + resp.data.lastName.toString().substring(0, 1)
  //       });
  //     })
  //     .catch((ex) => {
  //       this.setState({ loading: false });
  //       this.setState({ submitted: false });
  //     });
  //     this.props
  //     .getInActivePatientsList(studyId)
  //     .then((resp) => {
  //       this.setState({
  //         loading: false,
  //         patienteInactiveData: resp.data,
  //       });
  //     })
  //     .catch((ex) => {
  //       this.setState({ loading: false });
  //     });
  //   // if (this.props.approvedPatients) {
  //   //   this.setState({
  //   //     data: this.props.approvedPatients,
  //   //     loading: false
  //   //   });
  //   // }
  // };

  componentDidMount = async () => {
    this.setState({loading: true})
    let studyId = localStorage.getItem("studyId");
    // if (this.props.approvedPatients) {
    //   this.setState({
    //     data: this.props.approvedPatients,
    //     loading: false
    //   });
    // }
    if (studyId === "1") {
      await this.props
      .getApprovedPatientsList(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          data: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    } else if (studyId === "2"){

      // this.props
      // .getApprovedPatientsListJanssen()
      // .then((resp) => {
      //   this.setState({
      //     loading: false,
      //     data: resp.data,
      //   });
      // })
      await this.props.getApprovedPatientsListJanssen()
    .then((resp) => {
      // Filter the data to exclude users with userStatus equal to "DEACTIVE"
      const filteredData = resp.data.filter(item => item.userStatus !== "DEACTIVE");

      this.setState({
        loading: false,
        data: filteredData,
      });
    })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    } else if (studyId === "4") {
      await this.props.getApprovedPatientsListNih()
      .then((resp) => {
        // Filter the data to exclude users with userStatus equal to "DEACTIVE"
        const filteredData = resp.data.filter(item => item.userStatus !== "DEACTIVE");

        this.setState({
          loading: false,
          data: filteredData,
        });
      })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    } else {
      await this.props.getApprovedPatientsListParade()
      .then((resp) => {
        // Filter the data to exclude users with userStatus equal to "DEACTIVE"
        const filteredData = resp.data.filter(item => item.userStatus !== "DEACTIVE");
  
        this.setState({
          loading: false,
          data: filteredData,
        });
      })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
    this.props
      .getLoggedInUser()
      .then((resp) => {
        this.setState({
          loading: false,
          firstName: resp.data.firstName,
          email: resp.data.email,
          roleName:resp.data.roles[0].name,
          avatar: resp.data.firstName.toString().substring(0, 1) + resp.data.lastName.toString().substring(0, 1)
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
        this.setState({ submitted: false });
      });
      this.props
      .getInActivePatientsList(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          patienteInactiveData: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
    // if (this.props.approvedPatients) {
    //   this.setState({
    //     data: this.props.approvedPatients,
    //     loading: false
    //   });
    // }
  };

  componentWillReceiveProps = (props) => {
    if (props.approvedPatients) {
      this.setState({
        data: props.approvedPatients,
        loading: false
      });
    }
  }

  getColumnSearchProps = (dataIndex, studyId = localStorage.getItem("studyId")) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${studyId === "3" ? "participantId" : dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  getColumnSearchPropsNewRash = (dataIndex, studyId = localStorage.getItem("studyId")) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${studyId === "3" ? "new rash?" : dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex] === true ? "yes" : "no";
      return recordValue.includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleStatusState =(record, value) => {
    this.setState({paradePatId: record.id, paradePatVal: value, visibleHandleStatus: true});
  }

  handleStatus = async (record, value) => {
    this.setState({ isDsable: true });
    let studyId = localStorage.getItem("studyId");
    let self = this;
    this.setState({ submitted: true });
    this.setState({ loading: true });
    this.setState({checked: value});

    let patId = this.state.paradePatId;
    let patVal = this.state.paradePatVal 
    
    await self.props
      .updateUserStatus(studyId == "3" ? patId : record.id, studyId =="3" ? patVal : value)
      .then(async (response) => {
        if (response.error && response.error !== "") {
          notifyUser("Failed Updating status", "error");
          self.setState({ submitted: false, loading: false });
        } else {
          if (response.data && response.data !== "" && response.data.isSuccess === true) {
            let studyId = localStorage.getItem("studyId"); 
            if (studyId === "1") {
              this.props
              .getApprovedPatientsList(studyId)
              .then((resp) => {
                this.setState({
                  loading: false,
                  data: resp.data,
                });
              })
              .catch((ex) => {
                this.setState({ loading: false });
              });
            } else if (studyId === "2") {
              await this.props.getApprovedPatientsListJanssen()
              .then((resp) => {
                // Filter the data to exclude users with userStatus equal to "DEACTIVE"
                const filteredData = resp.data.filter(item => item.userStatus !== "DEACTIVE");

                this.setState({
                  // loading: false,
                  data: filteredData,
                });
              })
              .catch((ex) => {
                this.setState({ loading: false });
              });
            } else if (studyId === "3") {
              this.setState({visibleHandleStatus: false})
              await this.props.getApprovedPatientsListParade()
              .then((resp) => {
                // Filter the data to exclude users with userStatus equal to "DEACTIVE"
                const filteredData = resp.data.filter(item => item.userStatus !== "DEACTIVE");

                this.setState({
                  // loading: false,
                  data: filteredData,
                });
              })
              .catch((ex) => {
                this.setState({ loading: false });
              });
            this.setState({ isDsable: false });
            } else {
              await this.props.getApprovedPatientsListNih()
              .then((resp) => {
                // Filter the data to exclude users with userStatus equal to "DEACTIVE"
                const filteredData = resp.data.filter(item => item.userStatus !== "DEACTIVE");

                this.setState({
                  // loading: false,
                  data: filteredData,
                });
              })
              .catch((ex) => {
                this.setState({ loading: false });
              });
            }
            await notifyUser("Status Updated Successfully", "success");
            self.setState({ submitted: false, loading: false });
          }
        }
      });
      await this.componentDidMount();
  };

  onClick = () => {
    this.props.history.push("/add-user");
  };

  handleEdit = (id) => {
    let studyId = localStorage.getItem("studyId")
    if (studyId == "3") {
      this.props.history.push("/photo-gallery/" + id);
    } else {
      this.props.history.push("/patient-progress/" + id);
    }
  };

  reviewStudy = (id) => {
    this.props.history.push("/screening-questions/" + "approved" + "/" + id);
  };

  handleLogs = () => {
    this.props.history.push("/audit-logs");
  };

  handleHistory = (id) => {
    this.props.history.push("/medical-history/" + id);
  };

  addPatient = () => {
    this.setState({
      visible: true,
    });
  };

  // handleAdd = async () => {
  //   try {
  //     this.setState({isDsable: true});
  //     const values = await this.formRef.current.validateFields();
  //     const { password, patientId} = values;
  //     const data = await this.props.addPatientParade(password, patientId, "");
  //     if (data.data && data.data.status == "APPROVED") {
  //       notifyUser("Patient added successfully", "success");
  //       this.setState({ visible: false });
  //       this.formRef.current.resetFields();
  //       await this.componentDidMount();
  //     } else if(data.data && data.data.error) {
  //       notifyUser(data.data.error.message, "error");
  //     } else {
  //       notifyUser("Something went wrong! Please try again later", "error");
  //     }
  //     this.setState({isDisable: false});
  //   } catch (info) {
  //     notifyUser("All fields are required!", "error");
  //     this.setState({isDisable: false});
  //   }
  // };

  handleAdd = async () => {
    try {
      this.setState({isDsable: true});
      const values = await this.formRef.current.validateFields();
      const { password, patientId} = values;
      const data = await this.props.addPatientParade(password, patientId, "");
      if (data.data && data.data.status === "APPROVED") {
        notifyUser("Patient added successfully", "success");
        this.setState({ visible: false });
        this.formRef.current.resetFields();
        await this.componentDidMount();
      } else if (data.data && data.data.error) {
        // Ensure the error message is a string
        const errorMessage = data.data.error.message || "An error occurred"; // default message if error.message is undefined
        notifyUser(errorMessage, "error");
      } else {
        notifyUser("Something went wrong! Please try again later", "error");
      }
  
      this.setState({ isDsable: false });
    } catch (info) {
      notifyUser("All fields are required!", "error");
      this.setState({ isDsable: false });
    }
  };

  showBulkImportModal = () => {
    this.setState({ visibleBulkImport: true });
  };

  // handleBulkImport = async ({ file }) => {
  //   if (file.type !== 'text/csv') {
  //     message.error(`${file.name} is not a csv file`);
  //   } else {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     await this.props.uploadBulkUsers(formData);
  //     message.success(`${file.name} file uploaded successfully`);
  //   }
  // };

  handleSubmit = async () => {
    this.setState({ isDsable: true });
    const { selectedFile } = this.state;
    if (!selectedFile || selectedFile.status === "removed") {
      message.error('No file selected');
      this.setState({ isDsable: false });
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    await this.props.uploadBulkUsers(formData);
    message.success(`${selectedFile.name} file successfully uploaded. Once participants imported will send you a notification email.`);
    this.handleCancel(); // Close the modal after successful upload
    this.setState({ isDsable: false });
  };

  handleFileChange = ({ file, fileList }) => {
    if (file.type !== 'text/csv') {
      message.error(`${file.name} is not a csv file`);
    } else {
      this.setState({ selectedFile: file, fileList });
    }
  };

  // handleCancel = () => {
  //   this.setState({ selectedFile: null });
  //   this.props.onCancel();
  // };

  handleCancel = () => {
    if (this.state.visible == true) {
      this.formRef.current.resetFields();
    }
    this.setState({ selectedFile: null, fileList: [],  });
    this.setState({ visible: false, visibleBulkImport: false, visibleHandleStatus: false });
    // this.props.onCancel();
  };

  handleFileRemove = async (file) => {
    await this.setState({selectedFile: null, fileList: []})
    this.setState((state) => {
      const newFileList = state.fileList.filter((item) => item.uid !== file.uid);
      return {
        fileList: newFileList,
        selectedFile: null,
      };
    });
  };

  validatePassword = (rule, value) => {
    // if (!value) {
    //   return Promise.reject('Please input your password!');
    // }
    if (value.length < 8) {
      return Promise.reject('Password must be at least 8 characters long!');
    }
    if (!/[a-z]/.test(value)) {
      return Promise.reject('Password must contain at least one lowercase letter!');
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject('Password must contain at least one uppercase letter!');
    }
    if (!/[0-9]/.test(value)) {
      return Promise.reject('Password must contain at least one number!');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return Promise.reject('Password must contain at least one special character!');
    }
    return Promise.resolve();
  };
  

  render() {
    const studyId = localStorage.getItem("studyId");
    const {visibleBulkImport, fileList, visibleHandleStatus, isDsable} = this.state;
    const role = this.state.roleName;
    const replaceUnderscores = (str) => str.replace(/_/g, ' ');
    let columns =[]
      if(studyId === "3"){
        columns = [
          {
            title: "Participant id",
            dataIndex: "patientId",
            key: "patientId",
            sorter: (a, b) => a.patientId.localeCompare(b.patientId),
            width: "25%",
            ...this.getColumnSearchProps("patientId"),
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: "25%",
            render: (_, record) => (
              <Switch
                defaultChecked
                checked={record.userStatus === "ACTIVE" ? true : false}
                checkedChildren="Active"
                unCheckedChildren="InActive"
                // onChange={(value) => this.handleStatus(record, value)}
                onChange={(value) => this.handleStatusState(record, value)}
              />
            ),
          },
          {
            title: "Last Activity",
            width: "30%",
            render: (_, record) =>
              this.state.data.length >= 1 ? (
                <div>
                  {/* <span>{record.updatedAt} </span> */}
                  <span>
                    {moment(record.updatedAt)
                      .format(dateFormathh)}
                  </span>
                </div>
              ) : null,
          },
          // {
          //   title: "New Rash?",
          //   key: "isNewRashCreated",
          //   width: "20%",
          //   ...this.getColumnSearchPropsNewRash("isNewRashCreated"),
          //   render: (_, record) =>
          //     this.state.data.length >= 1 ? (
          //       <div>
          //         {/* <span>{record.updatedAt} </span> */}
          //         <span>
          //           {record.isNewRashCreated === true ? "Yes" : "No"}
          //         </span>
          //       </div>
          //     ) : null,
          // },
          {
            title: "Action",
            dataIndex: "action",
            width: "20%",
            render: (_, record) =>
              this.state.data.length >= 1 ? (
                <div className="approved-patients-icon">
                  <a onClick={() => this.handleEdit(record.id)}>
                    <Tooltip title="Study Progress">
                      <ReadOutlined />
                    </Tooltip>
                  </a>
                </div>
              ) : null,
          },
        ];      
        } else if(studyId === "2"){
          columns = [
            {
              title: "Last Name",
              dataIndex: "lastName",
              key: "lastName",
              sorter: (a, b) => a.lastName.localeCompare(b.lastName),
              width: "25%",
              ...this.getColumnSearchProps("lastName"),
            },
            {
              title: "First Name",
              dataIndex: "firstName",
              key: "firstName",
              sorter: (a, b) => a.firstName.localeCompare(b.firstName),
              width: "25%",
              ...this.getColumnSearchProps("firstName"),
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              width: "20%",
              sorter: (a, b) => a.email.localeCompare(b.email),
              ...this.getColumnSearchProps("email"),
            },
            {
              title: "Phone",
              dataIndex: "phoneNumber",
              key: "phoneNumber",
              width: "20%",
              sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
              ...this.getColumnSearchProps("phoneNumber"),
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              width: "30%",
              render: (_, record) => (
                <Switch
                  defaultChecked
                  checked={record.userStatus === "ACTIVE" ? true : false}
                  checkedChildren="Active"
                  unCheckedChildren="InActive"
                  onChange={(value) => this.handleStatus(record, value)}
                />
              ),
            },
            {
              title: "Does patient have eczema or not?",
              dataIndex: "healthy",
              width: "30%",
              render: (_, record) =>
                this.state.data && this.state.data.length >= 1 ? (                
                  record.isSufferingEczema === true ?
                  <span>Yes</span> :
                  <span>No</span>
                    
                  ) : null
            },    
            {
              // title: 'Medical History',
              title: "Medical",
              width: "30%",
              dataIndex: "action",
              render: (_, record) =>
                this.state.data.length >= 1 ? (
                  <div className="approved-patients-icon">
                    <a onClick={() => this.handleHistory(record.id)}>
                      <Tooltip title="Medical History">
                        <HistoryOutlined />
                      </Tooltip>
                    </a>
                  </div>
                ) : null,
            },
            {
              title: "Action",
              dataIndex: "action",
              width: "30%",
              render: (_, record) =>
                this.state.data.length >= 1 ? (
                  <div className="approved-patients-icon">
                    <a onClick={() => this.handleEdit(record.id)}>
                      <Tooltip title="Study Progress">
                        <ReadOutlined />
                      </Tooltip>
                    </a>
                  </div>
                ) : null,
            },
          ];      
        } else if (studyId === "4") {
            columns = [
              // {
              //   title: "Patient Name",
              //   dataIndex: "name",
              //   key: "name",
              //   sorter: (a, b) => a.name.localeCompare(b.name),
              //   width: "25%",
              //   ...this.getColumnSearchProps("name"),
              // },
              // {
              //   title: "Study Name",
              //   dataIndex: "studyName",
              //   key: "firstName",
              //   sorter: (a, b) => a.studyName.localeCompare(b.studyName),
              //   width: "25%",
              //   ...this.getColumnSearchProps("studyName"),
              // },
              // {
              //   title: "Patient Email",
              //   dataIndex: "email",
              //   key: "email",
              //   width: "20%",
              //   sorter: (a, b) => a.email.localeCompare(b.email),
              //   ...this.getColumnSearchProps("email"),
              // },
              // {
              //   title: "Age",
              //   dataIndex: "age",
              //   key: "age",
              //   width: "20%",
              //   sorter: (a, b) => a.age.localeCompare(b.age),
              //   ...this.getColumnSearchProps("age"),
              // },
              // {
              //   title: "Registered Date",
              //   width: "35%",
              //   render: (_, record) =>
              //     this.state.data.length >= 1 ? (
              //       <div>
              //         <span>{record.registeredDate} </span>
              //         <span>
              //           {moment(record.registeredDate)
              //             .format(dateFormathh)}
              //         </span>
              //       </div>
              //     ) : null,
              // },
              // {
              //   title: "Status",
              //   dataIndex: "status",
              //   key: "status",
              //   width: "30%",
              //   render: (_, record) => (
              //     <Switch
              //       defaultChecked
              //       checked={record.userStatus === "ACTIVE" ? true : false}
              //       checkedChildren="Active"
              //       unCheckedChildren="InActive"
              //       onChange={(value) => this.handleStatus(record, value)}
              //     />
              //   ),
              // },
              // {
              //   title: "Action",
              //   dataIndex: "action",
              //   width: "30%",
              //   render: (_, record) =>
              //     this.state.data.length >= 1 ? (
              //       <div className="approved-patients-icon">
              //         <a onClick={() => this.handleEdit(record.id)}>
              //           <Tooltip title="Study Progress">
              //             <ReadOutlined />
              //           </Tooltip>
              //         </a>
              //       </div>
              //     ) : null,
              // },

              {
                title: "Last Name",
                dataIndex: "lastName",
                key: "lastName",
                sorter: (a, b) => a.lastName.localeCompare(b.lastName),
                width: "15%",
                ...this.getColumnSearchProps("lastName"),
              },
              {
                title: "First Name",
                dataIndex: "firstName",
                key: "firstName",
                sorter: (a, b) => a.firstName.localeCompare(b.firstName),
                width: "15%",
                ...this.getColumnSearchProps("firstName"),
              },
              {
                title: "Email",
                dataIndex: "email",
                key: "email",
                width: "20%",
                sorter: (a, b) => a.email.localeCompare(b.email),
                ...this.getColumnSearchProps("email"),
              },
              {
                title: "Phone",
                dataIndex: "phoneNumber",
                key: "phoneNumber",
                width: "10%",
                sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
                ...this.getColumnSearchProps("phoneNumber"),
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                width: "10%",
                render: (_, record) => (
                  <Switch
                    defaultChecked
                    checked={record.userStatus === "ACTIVE" ? true : false}
                    checkedChildren="Active"
                    unCheckedChildren="InActive"
                    onChange={(value) => this.handleStatus(record, value)}
                  />
                ),
              },
              {
                title: "Patient Condition",
                dataIndex: "healthCondition",
                width: "15%",
                // sorter: (a, b) => replaceUnderscores(a.healthCondition).localeCompare(replaceUnderscores(b.healthCondition)),
                // ...this.getColumnSearchProps("healthCondition"),
                render: (_, record) =>
                  <span>{replaceUnderscores(record.healthCondition)}</span>
              },
              {
                // title: 'Medical History',
                title: "Participant Information",
                width: "15%",
                dataIndex: "action",
                render: (_, record) =>
                  this.state.data.length >= 1 ? (
                    <div className="approved-patients-icon">
                      <a onClick={() => this.handleHistory(record.id)}>
                        <Tooltip title="Participant Information">
                          <HistoryOutlined />
                        </Tooltip>
                      </a>
                    </div>
                  ) : null,
              },
              {
                title: "Action",
                dataIndex: "action",
                width: "10%",
                render: (_, record) =>
                  this.state.data.length >= 1 ? (
                    <div className="approved-patients-icon">
                      <a onClick={() => this.handleEdit(record.id)}>
                        <Tooltip title="Study Progress">
                          <ReadOutlined />
                        </Tooltip>
                      </a>
                    </div>
                  ) : null,
              },
            ]; 
        }
        else  { 
          columns = [
            {
              title: "Last Name",
              dataIndex: "lastName",
              key: "lastName",
              sorter: (a, b) => a.lastName.localeCompare(b.lastName),
              width: "25%",
              ...this.getColumnSearchProps("lastName"),
            },
            {
              title: "First Name",
              dataIndex: "firstName",
              key: "firstName",
              sorter: (a, b) => a.firstName.localeCompare(b.firstName),
              width: "25%",
              ...this.getColumnSearchProps("firstName"),
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              width: "20%",
              sorter: (a, b) => a.email.localeCompare(b.email),
              ...this.getColumnSearchProps("email"),
            },
            {
              title: "Phone",
              dataIndex: "phoneNumber",
              key: "phoneNumber",
              width: "20%",
              sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
              ...this.getColumnSearchProps("phoneNumber"),
            },
            {
              title: "Last Updated By",
              width: "35%",
              render: (_, record) =>
                this.state.data.length >= 1 ? (
                  <div>
                    <span>{record.updatedBy} </span>
                    <span>
                      {moment(record.updatedAt)
                        .format(dateFormathh)}
                    </span>
                  </div>
                ) : null,
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              width: "30%",
              render: (_, record) => (
                <Switch
                  defaultChecked
                  checked={record.userStatus === "ACTIVE" ? true : false}
                  checkedChildren="Active"
                  unCheckedChildren="InActive"
                  onChange={(value) => this.handleStatus(record, value)}
                />
              ),
            },
            {
              title: "Review",
              dataIndex: "review",
              width: "30%",
              render: (_, record) =>
                this.state.data.length >= 1 ? (
                  <div className="approved-patients-icon">
                    <a onClick={() => this.reviewStudy(record.id)}>
                      <Tooltip title="View Screening Questions">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </a>
                  </div>
                ) : null,
            },      
            {
              // title: 'Medical History',
              title: "Medical",
              width: "30%",
              dataIndex: "action",
              render: (_, record) =>
                this.state.data.length >= 1 ? (
                  <div className="approved-patients-icon">
                    <a onClick={() => this.handleHistory(record.id)}>
                      <Tooltip title="Medical History">
                        <HistoryOutlined />
                      </Tooltip>
                    </a>
                  </div>
                ) : null,
            },
            {
              title: "Action",
              dataIndex: "action",
              width: "30%",
              render: (_, record) =>
                this.state.data.length >= 1 ? (
                  <div className="approved-patients-icon">
                    <a onClick={() => this.handleEdit(record.id)}>
                      <Tooltip title="Study Progress">
                        <ReadOutlined />
                      </Tooltip>
                    </a>
                  </div>
                ) : null,
            },
          ];
        }
    return (
      <Layout>
        <Spin spinning={this.state.loading} >
          <Card title={studyId == 3 ? "Active Participant" :"Approved Patients"}
            extra={studyId == "3" ?[
             <Button onClick={this.showBulkImportModal} key="bulkImport">
                Bulk import Participants
              </Button>,
              <Button onClick={this.addPatient} key="addPatient">
                Add new Participant
              </Button>]: ""}>
              <Modal
                title="Add Participant"
                visible={this.state.visible}
                onOk={this.handleAdd}
                onCancel={this.handleCancel}
                okText="Add"
                okButtonProps={{ disabled: isDsable }}
              >
                <Form ref={this.formRef} name="login_form" className="add-patient-modal">
                  <Form.Item
                    name="patientId"
                    label="Participant id"
                    rules={[{ required: true, message: 'Please input Participant id!' }]}
                  >
                    <Input placeholder="Participant id" maxLength={4}/>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      { required: true, message: 'Please input your password!' },
                      { validator: this.validatePassword },
                    ]}
                  >
                    <Input type="password" placeholder="Password" />
                  </Form.Item>
                </Form>
              </Modal>
              <Modal
                title="Bulk Import Participants"
                visible={visibleBulkImport}
                onCancel={this.handleCancel}
                footer={[
                  <Button key="cancel" onClick={this.handleCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={this.handleSubmit}
                    disabled={isDsable}
                  >
                    Submit
                  </Button>,
                ]}
                className="bulk-import"
              >
                <Upload
                  accept=".csv"
                  beforeUpload={() => false} // Prevent automatic upload
                  onChange={this.handleFileChange}
                  fileList={fileList}
                  className="bulk-import-upload"
                  onRemove={this.handleFileRemove}
                >
                  <Button className="bulk-import-button" icon={<UploadOutlined />}>
                    Upload CSV File
                  </Button>
                </Upload>
              </Modal>
              <Modal
                // title="Bulk Import Participants"
                visible={visibleHandleStatus}
                onCancel={this.handleCancel}
                footer={[
                  <Button key="cancel" onClick={this.handleCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={this.handleStatus}
                    disabled={isDsable}
                  >
                    Yes
                  </Button>,
                ]}
                className="bulk-import"
              >
                <span>Are you sure you want to inactive the participant?</span>
              </Modal>
            <Table
              className={studyId =="3" ? "approved-patients-table centered-align" : "approved-patients-table"}
              columns={columns}
              bordered
              dataSource={this.state.data}
            />
          </Card>
        </Spin>
      </Layout>
    );
  }
}
function mapStateToProps(state) {
  return { defaultStudyId: state.study.defaultStudy };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PatientActions, ...AdminActtions, ...AuthActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ApprovedPatientList
  )
);
