import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";
import * as ActivityActions from "../../redux/actions/patient-activity-actions";

const dateFormat = "Do MMM";

class SleepMeasures extends Component {
  constructor(props) {
    super(props);
    this.state = { sleepData: [] }
  }

  async componentDidMount() {
    let patientId = this.props.match.params.id;
    let studyId = localStorage.getItem("studyId");
    let sleepData = [];

    if (studyId === "4") {
      sleepData = await this.props.getSleepInfo(patientId, studyId);
    } else {
      sleepData = await this.props.getSleepMeasures(patientId);
    }
    if (sleepData && sleepData.data && sleepData.data.length > 0) {
      this.setState({ sleepData: sleepData.data })
    }
  };

  render() {
    const { sleepData } = this.state;
    
    let series = [];
    let sleepTime = [];
    let bedTime = [];
    let seriesData = {}
    // if (sleepData) {
    //   sleepData.map((sleep, index) => {
    //     var obj = {};
    //     obj["x"] = moment(sleep.date).format(dateFormat);
    //     obj["y"] = sleep.time?sleep.time.split(":")[1]:0;
    //     sleepTime.push(obj);
    //   })
    //   sleepData.map((bed, index) => {
    //     var obj = {};
    //     obj["x"] = moment(bed.date).format(dateFormat);
    //     obj["y"] = bed.time?bed.time.split(":")[0]:0;
    //     bedTime.push(obj);
    //   })
    //   series.push({"name":"Asleep","data": sleepTime });
    //   series.push({"name":"In Bed", "data": bedTime });
    //   seriesData = series;
    // }

    if (sleepData) {
      sleepData.map((sleep, index) => {
        var obj = {};
        obj["x"] = moment(sleep.date).format(dateFormat);
        obj["y"] = sleep.time?sleep.time.split(":")[0]:0;
        sleepTime.push(obj);
      })
      sleepData.map((bed, index) => {
        var obj = {};
        obj["x"] = moment(bed.date).format(dateFormat);
        obj["y"] = bed.time?bed.time.split(":")[1]:0;
        bedTime.push(obj);
      })
      series.push({"name":"InBed","data": sleepTime });
      series.push({"name":"Asleep", "data": bedTime });
      seriesData = series;
    }



    const options = {
      chart: {
        type: 'bar',
        // height: 430,
        zoom: {
          enabled: true
        }
      },
      yaxis: {
        show: true,
        showAlways: false,
        showForNullSeries: true,
        seriesName: undefined,
        opposite: false,
        reversed: false,
        logarithmic: false,
        logBase: 10,
        tickAmount: undefined,
        min: undefined,
        max: undefined,
        stepSize: undefined,
        forceNiceScale: false,
        floating: false,
        decimalsInFloat: undefined,
        labels: {
            show: true,
            align: 'right',
            style: {
                colors: [],
                fontSize: '10px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
            },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          // dataLabels: {
          //   position: 'top',
          // }
        }
      },
      colors: ['#131b33', '#006297'],
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        offsetY: -2,
        style: {
          fontSize: '10px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 7,
        // height: 10,
        // colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
  };

    return (
      <div className="list-patients"  >
        {seriesData ? <ReactApexChart options={options} series={seriesData} type="bar" height={800} /> : ""}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sleepMeasures: state.activity.getSleepMeasures
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...ActivityActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SleepMeasures)
);
