import React, { Component } from "react";
import { Spin } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tabs, Layout, Card, Input, Button, Tooltip } from "antd";
import PatientList from "./new-patients-list";
import ApprovedPatientList from "./approved-patients-list";
import DisApprovedPatientList from "./disapproved-patients-list";
import DisqualifiedPatients from "./disqualified-patients";

import SideBar from "../layout/sidebar/sidebar.jsx";
import options from "../layout/sidebar/dashboard-options";
import UserHeader from "../layout/header.jsx";
import * as StudyActions from "../../redux/actions/study-actions";
import * as PatientActions from "../../redux/actions/patient-actions";
import * as AdminActtions from "../../redux/actions/admin-actions";
import * as AuthActions from "../../redux/actions/auth-actions"
import { DownloadOutlined } from "@ant-design/icons"
import { notifyUser } from "../../services/notification-service"


const { TabPane } = Tabs;
const { Sider, Header, Content } = Layout;

class PatientTabs extends Component {
  state = {
    key: 0,
    data: {},
    study: undefined,
    patients: undefined,
    approvedPatients: undefined,
    disapprovedPatients: undefined,
    inActivePatients: undefined,
    loading: true,
    searchString: undefined,
    searchedTab: undefined,
    searchKey: 1,
    roleName: "",
    showDisqualifiedPatients: false,
    showActivePatients: false,
    isDisabled: false,
  }

  componentDidMount = async () => {
    let key = this.props.match.params.key;
    this.setState({ key: key, loading: true })
    let studyId = localStorage.getItem("studyId");
    this.setState({ studyId: studyId });
    this.props
      .getStudyById(studyId)
      .then((resp) => {
        this.setState({
          // loading: false,
          data: resp.data
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });

      this.props
      .getLoggedInUser()
      .then((resp) => {
        this.setState({
          // loading: false,
          firstName: resp.data.firstName,
          email: resp.data.email,
          roleName:resp.data.roles[0].name,
          avatar: resp.data.firstName.toString().substring(0, 1) + resp.data.lastName.toString().substring(0, 1)
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
        this.setState({ submitted: false });
      });

    await this.getPatients(studyId);
    if (studyId == "1") {
      await this.getApprovedPatients(studyId);
    }
    await this.getDisapprovedPatients(studyId);
    if (studyId == "1" ){
      await this.getInactivePatients(studyId);
    }
    this.setState({ loading: false });
  };

  getPatients = async (studyId) => {
    this.setState({ loading: true })
    await this.props
      .getPatientsList(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          patients: resp.data
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  }

  getApprovedPatients = async (studyId) => {
    this.setState({ loading: true })
    await this.props
      .getApprovedPatientsList(studyId)
      .then((resp) => {
        this.setState({
          // loading: false,
          approvedPatients: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  }

  getDisapprovedPatients = async (studyId) => {
    this.setState({ loading: true })
    await this.props
      .getDisapprovedPatientsList(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          disapprovedPatients: resp.data
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  }

  getInactivePatients = async (studyId) => {
    this.setState({ loading: true })
    await this.props
      .getInActivePatientsList(studyId)
      .then((resp) => {
        this.setState({
          loading: false,
          inActivePatients: resp.data
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  }

  onSearch = async (value, key) => {
    let searchedTab = this.state.searchedTab ? this.state.searchedTab : [];
    searchedTab.push(key);
    this.setState({ loading: true, searchString: value, searchedTab: searchedTab });
    let self = this;
    let subType = key === '1' ? 'PENDING' : (key === '2' ? 'APPROVED' : (key === '3' ? 'SCREENFAILURE' : 'DEACTIVE'));

    if (value && value !== "") {
      await self.props
        .searchByKeyword({
          type: "PATIENT",
          searchString: value,
          subType: subType
        })
        .then((response) => {
          if (key === '1') {
            self.setState({ patients: response.data });
          } else if (key === '2') {
            self.setState({ approvedPatients: response.data });
          } else if (key === '3') {
            self.setState({ disapprovedPatients: response.data });
          } else if (key === '4') {
            self.setState({ inActivePatients: response.data });
          }
          self.setState({ loading: false });
        });
    } else {
      if (key === '1') {
        this.getPatients(this.state.studyId);
      } else if (key === '2') {
        this.getApprovedPatients(this.state.studyId);
      } else if (key === '3') {
        this.getDisapprovedPatients(this.state.studyId);
      } else if (key === '4') {
        this.getInactivePatients(this.state.studyId);
      }
    }
  };

  callback = (key) => {
    let studyId = localStorage.getItem("studyId");
    if (studyId == 2 || studyId == 3 || studyId == 4 ){
      this.setState((prevState) => ({
        showDisqualifiedPatients: !prevState.showDisqualifiedPatients,
        showActivePatients: !prevState.showActivePatients
      }));
    }
    this.setState({ key: key, searchString: undefined, searchKey: this.state.searchKey + 1 });
    if (this.state.searchedTab && this.state.searchedTab.length > 0 && this.state.searchString === "") {
      if (key === '1' && this.state.searchedTab.includes('1')) {
        this.getPatients(this.state.studyId);
      } else if (key === '2' && this.state.searchedTab.includes('2')) {
        this.getApprovedPatients(this.state.studyId);
      } else if (key === '3' && this.state.searchedTab.includes('3')) {
        this.getDisapprovedPatients(this.state.studyId);
      } else if (key === '4' && this.state.searchedTab.includes('4')) {
        this.getInactivePatients(studyId == 1 ? this.state.studyId : studyId);
      }
      this.setState({ searchedTab: this.state.searchedTab.filter(item => item !== key) })
    }
  };

  searchBar = <div className="search-bar"> <Input.Search aria-label="search" value={this.state.searchString} key={this.state.searchKey} placeholder="input search text" enterButton allowClear onSearch={(e) => this.onSearch(e, this.state.key)} style={{ width: 300 }} /></div>

  async downloadPatData() {
    await this.setState({isDisabled: true});
    notifyUser("Please wait while file is being downloaded","loading");
    let fileName = "participants_" + new Date().getTime() + ".csv"
    var response = await this.props.downloadParticipantData(fileName);
    console.log('response',response);    
    if (response) {
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet_stream' }));
      a.download = fileName;
      document.body.appendChild(a)
      a.click();
      document.body.removeChild(a)
      notifyUser("File successfully downloaded","success");
    } else {
      notifyUser("Something went wrong","error");
    }
    await this.setState({isDisabled: false});
  }
  render() {
    const { key, isDisabled } = this.state;
    const studyId = localStorage.getItem("studyId");
    const role = this.state.roleName;


    return (
      <Layout>

        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="260px"
          className="sidebar"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <SideBar {...this.props} options={options.dashBoardOptions} />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              background: "#f2f5f9",
              zIndex: 100,
              top: 0,
              width: "100%",
              padding: 0,
            }}
          >
            <UserHeader
              {...this.props}
              isCollapsed={this.state.collapsed}
              toggleSidebar={this.toggleSidebar}
            />
          </Header>
          <Content className="site-layout-background" >
            <Card 
              title={this.state.data && this.state.data != null && this.state.data.title}
              extra={studyId === "3" ?
                <Tooltip title="Download all participants data">
                  <Button 
                    onClick={() => this.downloadPatData()}
                    disabled={isDisabled}
                    >
                      <DownloadOutlined />
                      Participants Data
                  </Button> 
                </Tooltip>: ""}
              >
              <div className="patient-tab">
                <div className="card-container">
                  <Spin spinning={this.state.loading} >
                    <Tabs
                      defaultActiveKey={key ? key : '1'}
                      type="card"
                      // tabBarExtraContent={this.searchBar}
                      onChange={this.callback}
                    >
                      {role !== "SPUERADMIN" && (studyId == "2" || studyId == "3" || studyId == "4") ? null:
                      (<TabPane tab="Pending" key="1">
                        {this.state.patients && <PatientList patients={this.state.patients && this.state.patients} />}
                      </TabPane>) }
                      
                      {
                        studyId == "1" ? 
                        <TabPane tab="Approved Patients" key="2">
                          {this.state.approvedPatients && <ApprovedPatientList approvedPatients={this.state.approvedPatients && this.state.approvedPatients} />}
                        </TabPane> :
                        <TabPane tab={studyId == 3? "Active Participants" : "Approved Patients"} key="2">
                          {this.state.showActivePatients || <ApprovedPatientList />}
                        </TabPane>
                      }
                      {role !== "SPUERADMIN" && (studyId == "2" || studyId == "3" || studyId == "4") ? null:
                      (<TabPane tab="Screen Failure" key="3">
                        {this.state.disapprovedPatients && <DisApprovedPatientList disapprovedPatients={this.state.disapprovedPatients && this.state.disapprovedPatients} />}
                      </TabPane>)
                      }
                      {
                        studyId == "1" ?
                        <TabPane tab="Inactive Patients" key="4">
                        {this.state.inActivePatients && <DisqualifiedPatients inActivePatients={this.state.inActivePatients && this.state.inActivePatients} />}
                      </TabPane> :
                      <TabPane tab={studyId == 3 ? "Inactive Participants" : "Inactive Patients"} key="4">
                        {this.state.showDisqualifiedPatients && <DisqualifiedPatients />}
                      </TabPane>
                      }
                    </Tabs>
                  </Spin>
                </div>
              </div>
            </Card>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...StudyActions, ...AdminActtions, ...PatientActions, ...AuthActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(PatientTabs)
);
